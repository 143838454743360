import Routes from './routes/Routes';
import React from 'react';

//import { configureFakeBackend } from './helpers';

// Themes

// For Default import Default.scss
import './assets/scss/Default.scss';
//import './assets/scss/Modern.scss';
//import WebsocketComputation from './websocketComputation/websocketComputation';
//import {MyWs} from './websocketComputation/websocketComputation';


//import WebSocketProvider from './websocketComputation/WebSocket';
import ComputeStepProvider from './pages/apps/common/ComputeStep'
import WebSocketInst from './websocketComputation/WebSocketInst'


import NotificationGlobal from './pages/apps/notifications';
import WebSocket from './websocketComputation/WebSocket';


// configure fake backend
//configureFakeBackend();

import { configureStore } from './redux/store';

import { useSelector,Provider  } from 'react-redux';
import { SocketProvider } from './websocketComputation/SocketContext';

//import Routes_test from './Routes_test';


const App = () => {
   //const ws = WebsocketComputation();
   //MyWs.init();


    console.log("App::render !!!!!!! js")
    const storeCustom = configureStore({})

    return (
    <>
        <Provider store={storeCustom}>
 
            <SocketProvider>
                <ComputeStepProvider><Routes/></ComputeStepProvider> ;
            </SocketProvider>

            <NotificationGlobal/>
            
        </Provider>
    </>
    )
};

export default App;